import React from "react";
import Layout from "../component/layout";
import FooterComponent from "../component/footerComponent";
import WebsLogoSwiper from "../component/websLogoSwiper";
import ItProsConsComponent from "../component/Services/IT/ItProsConsComponent";
import Icon from "../images/card-icon.png";
import { graphql } from "gatsby";
import ItExpandTeamComponent from "../component/Services/IT/ItExpandTeamComponent";
import TechStackComponent from "../component/Services/techStackComponent";
import CustomDevelopmentCoreIndustries from "../component/Services/CustomDevelopment/customDevelopmentCoreIndustries";
//import Img from '../images/core-image-2.png';
import CustomDevelopmentVideoComponent from "../component/Services/CustomDevelopment/customDevelopmentVideoComponent";
import BookCallServiceComponent from "../component/bookCallServiceComponent";
import ServicesHomeComponentV2 from "../component/servicesHomeComponentV2";
//import HomeImage from '../images/outsourcing-dev-home-image.png';
import AccordionComponent from "../component/accordionComponent";
import CardsComponent from "../component/CardsComponent";
import BorderCards from "../component/BorderCards";

const ServiceOutsourcingDev = (props) => {
  const {
    data: {
      page1: {
        careerPositionSection: {
          careerPositionTitle,
          careerPositionDescription,
          careerPositionFornend,
          careerPositionBackend,
          careerPositionDevops,
          careerPositionDesigner,
          careerPositionQa,
          careerPositionProjectManagement,
          careerPositionProjectManager,
          careerPositionSupport,
          careerPositionCostumerSupport,
          careerPositionContentManager,
          careerPositionSocialMediaMarketing,
          careerPositionMarketingManager,
        },
      },
      page2: {
        seo: { title, metaDesc },
        companyMissionSection: {
          companyMissionTitle,
          companyMissionDescription,
          companyMissionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: HomeImage },
            },
          },
        },
        comprehensiveOutsourcedSoftwere: {
          cosTitle,
          cosDescription,
          cosSubtitle,
          cosText,
          cosCardTitle1,
          cosCardTitle2,
          cosCardTitle3,
          cosCardTitle4,
          cosCardTitle5,
          cosCardDescription1,
          cosCardDescription2,
          cosCardDescription3,
          cosCardDescription4,
          cosCardDescription5,
          cosParagraph1,
          cosParagraph2,
        },
        textField: {
          titleFieldTitle,
          textFieldSubtitle,
          textFieldCardTitle1,
          textFieldCardDescription1,
          textFieldCardTitle2,
          textFieldCardDescription2,
          textFieldCardTitle3,
          textFieldCardDescription3,
          textFieldCardTitle4,
          textFieldCardDescription4,
        },
        servicesItAugmentationSolutions: {
          customDevelopmentTitle,
          customDevelopmentDescription,
          customDevelopmentCardTitle1,
          customDevelopmentCardTitle2,
          customDevelopmentCardTitle3,
          customDevelopmentCardTitle4,
          customDevelopmentCardTitle5,
          customDevelopmentCardTitle6,
          customDevelopmentCardTitle7,
          customDevelopmentCardTitle8,
          customDevelopmentCardTitle9,
          customDevelopmentCardTitle10,
          customDevelopmentCardTitle11,
          customDevelopmentCardTitle12,
          customDevelopmentCardTitle13,
          customDevelopmentCardDescription1,
          customDevelopmentCardDescription2,
          customDevelopmentCardDescription3,
          customDevelopmentCardDescription4,
          customDevelopmentCardDescription5,
          customDevelopmentCardDescription6,
          customDevelopmentCardDescription7,
          customDevelopmentCardDescription8,
          customDevelopmentCardDescription9,
          customDevelopmentCardDescription10,
          customDevelopmentCardDescription11,
          customDevelopmentCardDescription12,
          customDevelopmentCardDescription13,
        },
        digitalApplicationDevelopmentServices: {
          digitalApplicationDevelopmentFrontend,
          digitalApplicationDevelopmentBackend,
          digitalApplicationDevelopmentProgressive,
          digitalApplicationDevelopmentSaas,
          digitalApplicationDevelopmentECommerce,
          digitalApplicationDevelopmentCustom,
          digitalApplicationDevelopmentTitle,
        },
        servicePageTeamSection: {
          servicePageTeamTitle,
          servicePageTeamDescription,
          servicePageTeamProduct,
          servicePageTeamBusinessAnalystTitle,
          servicePageTeamBusinessAnalystDescription,
          servicePageTeamProductTitle,
          servicePageTeamProductDescription,
          servicePageTeamDesignTitle,
          servicePageTeamDesignDescription,
          servicePageTeamWriterTitle,
          servicePageTeamWriterDescription,
          servicePageTeamImplementation,
          servicePageTeamManageTitle,
          servicePageTeamManagerDescription,
          servicePageTeamFrontendTitle,
          servicePageTeamFrontendDescription,
          servicePageTeamBackendTitle,
          servicePageTeamBackendDescription,
          servicePageTeamDevopsTitle,
          servicePageTeamDevopsDescription,
        },
        processSection: {
          processSubtitle,
          processDescription,
          processAnalysisTitle,
          processAnalysisDescription,
          processProductTitle,
          processProductDescription,
          processDesigningTitle,
          processDesigningDescription,
          processAgileTitle,
          processAgileDescription,
        },
        homePageHeaderSection: {
          homeHeaderTitle,
          homeHeaderDescription,
          homeHeaderBookButton,
          homeHeaderImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: image },
            },
          },
        },
      },
    },
  } = props;

  const seo = {
    title: "Best Software Development Outsourcing Services | Motomtech",
    metaDesc:
      "Bring your idea to reality with outsourced development services. Engage with IT professionals including project managers, developers, designers and more.",
  };

  const arr1 = [
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Project Analysis
          <span> and Planning:</span>
        </p>
      ),
      description: textFieldCardTitle1,
      number: "1",
    },

    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Selection of the
          <span> Development Team:</span>
        </p>
      ),
      description: textFieldCardDescription1,
      number: "2",
    },

    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          System Design and
          <span> Architecture:</span>
        </p>
      ),
      description: textFieldCardTitle2,
      number: "3",
    },

    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Develo
          <span>pment:</span>
        </p>
      ),
      description: textFieldCardDescription2,
      number: "4",
    },
  ];
  const arr2 = [
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Quality Assurance
          <span> and Testing:</span>
        </p>
      ),
      description: textFieldCardTitle3,
      number: "5",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Deployment and
          <span> Delivery:</span>
        </p>
      ),
      description: textFieldCardDescription3,
      number: "6",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Post-launch Support
          <span> and Maintenance:</span>
        </p>
      ),
      description: textFieldCardTitle4,
      number: "7",
    },
  ];

  const arr3 = [
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Resource
          <span> Allocation:</span>
        </p>
      ),
      description: servicePageTeamBusinessAnalystTitle,
      number: "1",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Cost
          <span> Management:</span>
        </p>
      ),
      description: servicePageTeamBusinessAnalystDescription,
      number: "2",
    },
  ];
  const arr4 = [
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Expe
          <span>rtise:</span>
        </p>
      ),
      description: servicePageTeamProductTitle,
      number: "3",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Project
          <span> Management: </span>
        </p>
      ),
      description: servicePageTeamProductDescription,
      number: "4",
    },
  ];
  const arr5 = [
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Reduced
          <span> Risk:</span>
        </p>
      ),
      description: servicePageTeamDesignDescription,
      number: "1",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Reduced
          <span> Cost:</span>
        </p>
      ),
      description: servicePageTeamWriterTitle,
      number: "2",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Professional and
          <span> Experienced:</span>
        </p>
      ),
      description: servicePageTeamWriterDescription,
      number: "3",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Vetting
          <span> Ideas:</span>
        </p>
      ),
      description: servicePageTeamImplementation,
      number: "4",
    },
  ];
  const arr6 = [
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Access to Global
          <span> Talent Pool:</span>
        </p>
      ),
      description: servicePageTeamManageTitle,
      number: "5",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Focus on Core
          <span> Business:</span>
        </p>
      ),
      description: servicePageTeamManagerDescription,
      number: "6",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Faster Time to
          <span> Market:</span>
        </p>
      ),
      description: servicePageTeamFrontendTitle,
      number: "7",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Easily
          <span> Scalable:</span>
        </p>
      ),
      description: servicePageTeamFrontendDescription,
      number: "8",
    },
  ];
  const arr7 = [
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Understand Your
          <span> Requirements: </span>
        </p>
      ),
      description: servicePageTeamBackendDescription,
      number: "1",
    },
  ];

  const arr8 = [
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Consider Budget
          <span> from Start to Finish:</span>
        </p>
      ),
      description: servicePageTeamDevopsTitle,
      number: "2",
    },
  ];
  const cards = [
    {
      icon: Icon,
      title: customDevelopmentCardTitle1,
      description: customDevelopmentCardDescription1,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle2,
      description: customDevelopmentCardDescription2,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle3,
      description: customDevelopmentCardDescription3,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle4,
      description: customDevelopmentCardDescription4,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle5,
      description: customDevelopmentCardDescription5,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle6,
      description: customDevelopmentCardDescription6,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle7,
      description: customDevelopmentCardDescription7,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle8,
      description: customDevelopmentCardDescription8,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle9,
      description: customDevelopmentCardDescription9,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle10,
      description: customDevelopmentCardDescription10,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle11,
      description: customDevelopmentCardDescription11,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle12,
      description: customDevelopmentCardDescription12,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle13,
      description: customDevelopmentCardDescription13,
    },
  ];

  const questions1 = [
    {
      title: processAnalysisTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processProductDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 1,
    },
    {
      title: processProductTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processAnalysisDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 2,
    },
  ];

  const questions2 = [
    {
      title: processDesigningTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processDesigningDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 3,
    },
    {
      title: processAgileTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processAgileDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 4,
    },
  ];
  return (
    <Layout seo={seo} title={seo.title}>
      <ServicesHomeComponentV2
        homeTitle={companyMissionTitle}
        homeDescription={companyMissionDescription}
        homeImage={HomeImage}
        homeButton={"Request our services"}
      />
      <WebsLogoSwiper />
      <div
        style={{
          background:
            "radial-gradient(90% 80% at 50% 50%, rgba(189, 217, 251, 0.4) 0%, #FFFFFF 100%)",
          paddingBottom: "40px",
        }}
      >
        <div className="full-container">
          <p
            className="outsourcing-dev-comprehesive-title"
            dangerouslySetInnerHTML={{ __html: cosTitle }}
          />
          <p
            className="outsourcing-dev-comprehesive-desc"
            dangerouslySetInnerHTML={{ __html: cosDescription }}
          />
        </div>
        <BorderCards
          whiteBackground={true}
          borderCardsTitle={cosSubtitle}
          borderCardsDescription={cosText}
          borderCardsText1={cosCardTitle1}
          borderCardsText2={cosCardDescription1}
          borderCardsText3={cosCardTitle2}
          borderCardsText4={cosCardDescription2}
          borderCardsText5={cosCardTitle3}
          borderCardsText6={cosCardDescription3}
          borderCardsText7={cosCardTitle4}
          borderCardsText8={cosCardDescription4}
          borderCardsText9={cosCardTitle5}
          borderCardsText10={cosCardDescription5}
        />
        <div className="full-container">
          <p
            className="outsourcing-dev-comprehesive-desc"
            dangerouslySetInnerHTML={{ __html: cosParagraph1 }}
          />
          <p
            className="outsourcing-dev-comprehesive-desc"
            dangerouslySetInnerHTML={{ __html: cosParagraph2 }}
          />
        </div>
      </div>
      <ItProsConsComponent
        title={titleFieldTitle}
        description={textFieldSubtitle}
        description2={textFieldCardDescription4}
        arr1={arr1}
        arr2={arr2}
      />

      <CardsComponent
        title={customDevelopmentTitle}
        description={customDevelopmentDescription}
        cards={cards}
      />
      <div style={{ position: "relative", zIndex: "3" }}>
        <BorderCards
          borderCardsMainTitle={"How our Software Development Services work"}
          borderCardsTitle={careerPositionTitle}
          borderCardsDescription={careerPositionDescription}
          borderCardsText1={careerPositionFornend}
          borderCardsText2={careerPositionBackend}
          borderCardsText3={careerPositionDevops}
          borderCardsText4={careerPositionDesigner}
          borderCardsText5={careerPositionQa}
          borderCardsText6={careerPositionProjectManagement}
          borderCardsText7={careerPositionProjectManager}
          borderCardsText8={careerPositionSupport}
          borderCardsText9={careerPositionCostumerSupport}
          borderCardsText10={careerPositionContentManager}
          borderCardsText11={careerPositionSocialMediaMarketing}
          borderCardsText12={careerPositionMarketingManager}
        />
      </div>
      <ItExpandTeamComponent
        title={digitalApplicationDevelopmentFrontend}
        description1={digitalApplicationDevelopmentBackend}
        description2={digitalApplicationDevelopmentProgressive}
        description3={digitalApplicationDevelopmentSaas}
        description4={digitalApplicationDevelopmentECommerce}
        description5={digitalApplicationDevelopmentCustom}
        description6={digitalApplicationDevelopmentTitle}
      />

      <div
        style={{
          background:
            "linear-gradient(89.77deg, #11123d 0.21%, #2c3976 99.82%)",
        }}
        className="outsourcing-inhouse-team"
      >
        <p
          className="outsourcing-inhouse-team-title"
          dangerouslySetInnerHTML={{ __html: servicePageTeamTitle }}
        />
        <p
          className="outsourcing-inhouse-team-subtitle"
          dangerouslySetInnerHTML={{ __html: servicePageTeamDescription }}
        />
        <div className="outsourcing-inhouse-team-content content-1">
          <ItProsConsComponent
            arr1={arr3}
            arr2={arr4}
            description={servicePageTeamProduct}
          />
        </div>
        <div className="outsourcing-inhouse-team-content content-2">
          <ItProsConsComponent
            arr1={arr5}
            arr2={arr6}
            description={servicePageTeamDesignTitle}
          />
        </div>
        <div className="outsourcing-inhouse-team-content content-3">
          <ItProsConsComponent
            arr1={arr7}
            arr2={arr8}
            description={servicePageTeamBackendTitle}
          />
        </div>
        <p
          className="outsourcing-inhouse-team-description"
          dangerouslySetInnerHTML={{ __html: servicePageTeamDevopsDescription }}
        />
      </div>
      <TechStackComponent />
      <CustomDevelopmentCoreIndustries
        title={homeHeaderTitle}
        text1={homeHeaderDescription}
        text2={homeHeaderBookButton}
        image={image}
      />
      <div style={{ position: "relative" }}>
        <CustomDevelopmentVideoComponent />
      </div>
      <AccordionComponent
        title={processSubtitle}
        subtitle={processDescription}
        arr1={questions1}
        arr2={questions2}
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <BookCallServiceComponent
          bookCallServiceTitle={"Outsource your development with Motomtech"}
          bookCallServiceDescription={"Stop wasting time."}
          isFirstService={true}
          isSecondService={false}
        />
      </div>

      <FooterComponent />
    </Layout>
  );
};

export default ServiceOutsourcingDev;
export const query = graphql`
  {
    page1: wpPage(slug: { eq: "saas-mvp" }) {
      careerPositionSection {
        careerPositionTitle
        careerPositionDescription
        careerPositionFornend
        careerPositionBackend
        careerPositionDevops
        careerPositionDesigner
        careerPositionQa
        careerPositionProjectManagement
        careerPositionProjectManager
        careerPositionSupport
        careerPositionCostumerSupport
        careerPositionContentManager
        careerPositionSocialMediaMarketing
        careerPositionMarketingManager
      }
    }
    page2: wpPage(slug: { eq: "service-outsource-development" }) {
      seo {
        title
        metaDesc
      }
      companyMissionSection {
        companyMissionTitle
        companyMissionDescription
        companyMissionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      comprehensiveOutsourcedSoftwere {
        cosTitle
        cosDescription
        cosSubtitle
        cosText
        cosCardTitle1
        cosCardTitle2
        cosCardTitle3
        cosCardTitle4
        cosCardTitle5
        cosCardDescription1
        cosCardDescription2
        cosCardDescription3
        cosCardDescription4
        cosCardDescription5
        cosParagraph1
        cosParagraph2
      }
      textField {
        titleFieldTitle
        textFieldSubtitle
        textFieldCardTitle1
        textFieldCardDescription1
        textFieldCardTitle2
        textFieldCardDescription2
        textFieldCardTitle3
        textFieldCardDescription3
        textFieldCardTitle4
        textFieldCardDescription4
      }
      careerPositionSection {
        careerPositionTitle
        careerPositionDescription
        careerPositionFornend
        careerPositionBackend
        careerPositionDevops
        careerPositionDesigner
        careerPositionQa
        careerPositionProjectManagement
        careerPositionProjectManager
        careerPositionSupport
        careerPositionCostumerSupport
        careerPositionContentManager
        careerPositionSocialMediaMarketing
        careerPositionMarketingManager
      }
      servicesItAugmentationSolutions {
        customDevelopmentTitle
        customDevelopmentDescription
        customDevelopmentCardTitle1
        customDevelopmentCardTitle2
        customDevelopmentCardTitle3
        customDevelopmentCardTitle4
        customDevelopmentCardTitle5
        customDevelopmentCardTitle6
        customDevelopmentCardTitle7
        customDevelopmentCardTitle8
        customDevelopmentCardTitle9
        customDevelopmentCardTitle10
        customDevelopmentCardTitle11
        customDevelopmentCardTitle12
        customDevelopmentCardTitle13
        customDevelopmentCardDescription1
        customDevelopmentCardDescription2
        customDevelopmentCardDescription3
        customDevelopmentCardDescription4
        customDevelopmentCardDescription5
        customDevelopmentCardDescription6
        customDevelopmentCardDescription7
        customDevelopmentCardDescription8
        customDevelopmentCardDescription9
        customDevelopmentCardDescription10
        customDevelopmentCardDescription11
        customDevelopmentCardDescription12
        customDevelopmentCardDescription13
      }
      digitalApplicationDevelopmentServices {
        digitalApplicationDevelopmentFrontend
        digitalApplicationDevelopmentBackend
        digitalApplicationDevelopmentProgressive
        digitalApplicationDevelopmentSaas
        digitalApplicationDevelopmentECommerce
        digitalApplicationDevelopmentCustom
        digitalApplicationDevelopmentTitle
      }
      servicePageTeamSection {
        servicePageTeamTitle
        servicePageTeamDescription
        servicePageTeamProduct
        servicePageTeamBusinessAnalystTitle
        servicePageTeamBusinessAnalystDescription
        servicePageTeamProductTitle
        servicePageTeamProductDescription
        servicePageTeamDesignTitle
        servicePageTeamDesignDescription
        servicePageTeamWriterTitle
        servicePageTeamWriterDescription
        servicePageTeamImplementation
        servicePageTeamManageTitle
        servicePageTeamManagerDescription
        servicePageTeamFrontendTitle
        servicePageTeamFrontendDescription
        servicePageTeamBackendTitle
        servicePageTeamBackendDescription
        servicePageTeamDevopsTitle
        servicePageTeamDevopsDescription
      }
      processSection {
        processSubtitle
        processDescription
        processAnalysisTitle
        processAnalysisDescription
        processProductTitle
        processProductDescription
        processDesigningTitle
        processDesigningDescription
        processAgileTitle
        processAgileDescription
        processLaunchingTitle
        processLaunchingDescription
      }
      homePageHeaderSection {
        homeHeaderTitle
        homeHeaderDescription
        homeHeaderBookButton
        homeHeaderImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
