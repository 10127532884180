import React from 'react';

const ItProsConsComponent = props => {
  const { arr1, arr2, title, description, description2, text1, text2 } = props;

  return (
    <div className='it-pros-cons'>
      <p
        className='it-pros-cons__title'
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <p
        className='it-pros-cons__description'
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className='full-container'>
        <div className='it-pros-cons__wrapper'>
          <div className='it-pros-cons__wrapper-col1'>
            <h2
              className='it-pros-cons__wrapper-col-title'
              dangerouslySetInnerHTML={{ __html: text1 }}
            />
            {arr1.map((item, i) => (
              <div className={`it-pros-cons__wrapper-content`} key={i}>
                <div className='it-pros-cons__wrapper-content-icon'>
                  <p dangerouslySetInnerHTML={{ __html: item.number }} />
                </div>
                <div className='it-pros-cons__wrapper-content-text'>
                  {item.title}
                  <p
                    className='it-pros-cons__wrapper-content-text-description'
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className='it-pros-cons__wrapper-col2'>
            <h2
              className='it-pros-cons__wrapper-col-title'
              dangerouslySetInnerHTML={{ __html: text2 }}
            />
            {arr2.map((item, i) => (
              <div
                className={`it-pros-cons__wrapper-content ${
                  item.number == 4 && 'child-4'
                }`}
                key={i}
              >
                <div className='it-pros-cons__wrapper-content-icon'>
                  <p>{item.number}</p>
                </div>
                <div className='it-pros-cons__wrapper-content-text'>
                  {item.title}
                  <p
                    className='it-pros-cons__wrapper-content-text-description'
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <p
        className='it-pros-cons__description'
        dangerouslySetInnerHTML={{ __html: description2 }}
      />
    </div>
  );
};

export default ItProsConsComponent;
